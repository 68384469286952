import { z } from 'zod'

export const CollaborationUserProfileColorSchema = z.object({
  primary: z.string(),
  secondary: z.string(),
})

export type CollaborationUserProfileColor = z.infer<
  typeof CollaborationUserProfileColorSchema
>

export const CollaborationUserProfileSchema = z.object({
  userId: z.string(),
  displayName: z.string().nullish(),
  color: CollaborationUserProfileColorSchema,
})

export type CollaborationUserProfile = z.infer<
  typeof CollaborationUserProfileSchema
>
