import { toSeconds } from 'duration-fns'
import { type Server as WebsocketServer, type Socket } from 'socket.io'
import { z } from 'zod'
import { CollaborationUserProfileSchema } from './user.ts'

export const COLLABORATION_REDIS_TTL = toSeconds({ minutes: 15 })
export const COLLABORATION_CHANNEL_JOIN_KEY = 'collab:join'
export const COLLABORATION_CHANNEL_LEAVE_KEY = 'collab:leave'
export const COLLABORATION_CHANNEL_CHANGE_KEY = 'collab:change'
export const COLLABORATION_CHANNEL_ERROR_KEY = 'collab:error'

export type UserInfo = {
  userId: string
  channelId: string
}
export type CollaborationCallback = (
  wss: WebsocketServer,
  socket: Socket,
  userInfo: UserInfo,
  data?: any,
) => void

export const GenericEventDataSchema = z.object({
  itineraryId: z.string(), // fixating to `itineraryId` for now
})

// CollaborationChannelSchema is used to join or leave the channel
export const CollaborationChannelSchema = z.object({
  userId: z.string(),
  displayName: z.string().nullish(),
  itineraryId: z.string(),
})

export type CollaborationChannelRequestPayload = z.infer<
  typeof CollaborationChannelSchema
>

// CollaborationChannelStateSchema is the shared state for the channel across all users
export const CollaborationChannelStateSchema = z.object({
  users: z.record(z.string(), CollaborationUserProfileSchema),
})

export type CollaborationChannelState = z.infer<
  typeof CollaborationChannelStateSchema
>

export function getChannelId(topic: string): string {
  return `collab:${topic}`
}

export function getChannelTopic(channelId: string): string | false {
  const maybeTopic = channelId.replace(/^collab:/, '')
  return maybeTopic !== channelId && maybeTopic
}
